<template>
    <div>
        <CataBueno :titulo="'Septiembre 2024'" :subtitulo="''" :filtros="filtros" :articles="articles"/>
    </div>
</template>

<script>
import CataBueno from '../../pagesComponents/CataBueno.vue';

export default {
components: { CataBueno },
data() {
    return {
    articles: [
        {
            "id": "0072-ART-APRENDE-TENDENCIAS-130924",
            "titulo": "La Casa De Los Famosos México 2: más allá del raiting",
            "fecha": "13 de septiembre de 2024",
            "autor": "Iván Solís Rivera",
            "descripcion": "Mira que hay detrás de La Casa de los Famosos 2 y cómo va más allá del rating, explorando su impacto en el entretenimiento y la cultura de masas.",
            "portada": "/images/casa-famosos-mexico.webp",
            "enlace": "/aprende/tendencias/la-casa-de-los-famosos-mexico-2-analisis"
        },
        {
            "id": "0073-ART-APRENDE-TENDENCIAS-130924",
            "titulo": "Origen y evolución de los reality shows: una mirada sociológica y de comunicación",
            "fecha": "13 de septiembre de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Explora cómo los reality shows, desde su origen hasta los más actuales, reflejan cambios sociales y comunicativos en la cultura global.",
            "portada": "/images/origen-reality-show-big-brother-analisis-social-y-comunicacion.webp",
            "enlace": "/aprende/tendencias/origen-evolucion-reality-shows-sociologia-comunicacion-lcdelfm2"
        },
        {
            "id": "0071-ART-ACADEMICO-LICENCIATURA-050924",
            "titulo": "Arte contemporáneo en México: Evolución, movimientos y artistas",
            "fecha": "05 de septiembre de 2024",
            "autor": "Iván Solís Rivera",
            "descripcion": "Arte contemporáneo en México: Desde el muralismo hasta las expresiones vanguardistas actuales, reflejo de la influencia internacional y multiculturalidad.",
            "portada": "/images/arte-contemporaneo-mexico.webp",
            "enlace": "/academico/licenciatura/arte-contemporaneo-en-mexico"
        },
        {
            "id": "0066-ART-ACADEMICO-LICENCIATURA-030924",
            "titulo": "El coaching de vida: características y cómo podría ayudarte",
            "fecha": "03 de septiembre de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Explora qué es el coaching de vida, sus orígenes, tipos, beneficios y más.",
            "portada": "/images/que-es-coaching-de-vida-caracteristicas-beneficios.webp",
            "enlace": "/academico/licenciatura/coaching-de-vida-como-podria-ayudarte"
        },
        {
            "id": "0065-ART-SER-HUMANITAS-COMUNIDAD-030924",
            "titulo": "Universidad Humanitas campus Querétaro: Educación de alto nivel",
            "fecha": "03 de septiembre de 2024",
            "autor": "Redacción Humanitas",
            "descripcion": "La Universidad Humanitas campus Querétaro ofrece a la comunidad universitaria instalaciones de primer nivel y modalidades de estudio flexibles.",
            "portada": "/images/universidad-humanitas-campus-queretaro.webp",
            "enlace": "/ser-humanitas/comunidad/universidad-humanitas-campus-queretaro"
        },
        
    ], // Array de artículos original
    filtros: {
        "0072-ART-APRENDE-TENDENCIAS-130924": {
            Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y teatro"],
            Ciencias_sociales: ["Derecho", "Ciencias Políticas y Administración Pública"],
            Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "0073-ART-APRENDE-TENDENCIAS-130924": {
            Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y teatro"],
            Ciencias_sociales: ["Derecho", "Ciencias Políticas y Administración Pública"],
            Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "0071-ART-ACADEMICO-LICENCIATURA-050924": {
            Humanidades: ["Arte y teatro"],
            Ciencias_sociales: [],
            Economico_administrativas: []
        },
        "0066-ART-ACADEMICO-LICENCIATURA-030924": {
            Humanidades: [ "Psicología" ],
            Ciencias_sociales: [],
            Economico_administrativas: []
        },
        "0065-ART-SER-HUMANITAS-COMUNIDAD-030924": {
            Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y teatro"],
            Ciencias_sociales: ["Derecho", "Ciencias Políticas y Administración Pública"],
            Economico_administrativas: ["Administración", "Contabilidad"]
        },
    },
    filtrosSeleccionados: null, // Lista de filtros seleccionados
    };
},
methods: {
    
}
};
</script>

<style>
/* Estilos CSS si los tienes */
</style>