<template>
    <div>
        <CataBueno :titulo="'Aprende'" :subtitulo="''" :filtros="filtros" :articles="articles"/>
    </div>
</template>

<script lang="js">
import CataBueno from './pagesComponents/CataBueno.vue';

export default {
components: { CataBueno },
data() {
    return {
    articles: [
        {
            "id": "0072-ART-APRENDE-TENDENCIAS-130924",
            "titulo": "La Casa De Los Famosos México 2: más allá del raiting",
            "fecha": "13 de septiembre de 2024",
            "autor": "Iván Solís Rivera",
            "descripcion": "Mira que hay detrás de La Casa de los Famosos 2 y cómo va más allá del rating, explorando su impacto en el entretenimiento y la cultura de masas.",
            "portada": "/images/casa-famosos-mexico.webp",
            "enlace": "/aprende/tendencias/la-casa-de-los-famosos-mexico-2-analisis"
        },
        {
            "id": "0073-ART-APRENDE-TENDENCIAS-130924",
            "titulo": "Origen y evolución de los reality shows: una mirada sociológica y de comunicación",
            "fecha": "13 de septiembre de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Explora cómo los reality shows, desde su origen hasta los más actuales, reflejan cambios sociales y comunicativos en la cultura global.",
            "portada": "/images/origen-reality-show-big-brother-analisis-social-y-comunicacion.webp",
            "enlace": "/aprende/tendencias/origen-evolucion-reality-shows-sociologia-comunicacion-lcdelfm2"
        },
        {
            "id": "0058-ART-APRENDE-TIPS-010824",
            "titulo": "¿Qué hacer si no te quedaste en el IPN?, aquí la respuesta",
            "fecha": "01 de agosto de 2024",
            "autor": "Iván Solís Rivera",
            "descripcion": "¿Qué hacer si no te quedaste en el IPN? Descubre las mejores opciones educativas públicas y privadas de educación superior.",
            "portada": "/images/que-hacer-quedaste-ipn.webp",
            "enlace": "/aprende/tips/que-hacer-si-no-te-quedaste-en-el-ipn"
        },
        {
            "id": "0057-ART-APRENDE-TIPS-310724",
            "titulo": "¿No te quedaste en la UNAM? Descubre estas alternativas",
            "fecha": "31 de julio de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "¿Si presentaste tu examen de admisión a la UNAM y no fuiste seleccionado, te damos alternativas para continuar tu educación universitaria. ¡Conócelas!",
            "portada": "/images/no-te-quedaste-unam-universidad-humanitas.webp",
            "enlace": "/aprende/tips/no-te-quedaste-en-la-unam-descubre-estas-alternativas"
        },
        {
            "id": "0055-ART-APRENDE-TIPS-050724",
            "titulo": "¿Ansiedad es lo mismo que angustia?: Aquí la respuesta",
            "fecha": "08 de julio de 2024",
            "autor": "Iván Solís Rivera",
            "descripcion": "Alguna vez te haz preguntado: ¿Ansiedad es lo mismo que angustia?, ¿Haz experimentado estos padecimientos?, te decimos cómo evitarlos.",
            "portada": "/images/ansiedad-mismo-angustia.webp",
            "enlace": "/aprende/tips/ansiedad-es-lo-mismo-que-angustia"
        },
        {
            "id": "0056-ART-APRENDE-CULTURA-GENERAL-070724",
            "titulo": "¿En qué países se habla el idioma inglés? Guía completa, entérate",
            "fecha": "07 de julio de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "En esta guía, conoce el origen y en qué países se habla inglés, ¡te sorprenderás!",
            "portada": "/images/descubre-los-paises-donde-se-habla-ingles.webp",
            "enlace": "/aprende/cultura-general/en-que-paises-se-habla-el-idioma-ingles"
        },
        {
            "id": "0051-ART-APRENDE-CULTURA-GENERAL-270624",
            "titulo": "Día Mundial de las Redes Sociales: Celebrando el 30 de junio",
            "fecha": "27 de junio de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Día Mundial de las Redes Sociales: conoce por qué se celebra y cómo las redes sociales influyen en nuestras vidas.",
            "portada": "/images/dia-mundial-redes-sociales-30-junio.webp",
            "enlace": "/aprende/cultura-general/dia-mundial-redes-sociales-30-junio-2024"
        },
        {
            "id": "0044-ART-APRENDE-CULTURA-GENERAL-130624",
            "titulo": "¿Cuántos pisos tiene la torre de Pemex y cuál es su historia?",
            "fecha": "13 de junio de 2024",
            "autor": "Iván Solís Rivera",
            "descripcion": "Conoce cuántos pisos tiene la torre de Pemex y cuál es la historia de este edificio, que es símbolo de la arquitectura moderna mexicana.",
            "portada": "/images/cuantos-pisos-tiene-torre-pemex.webp",
            "enlace": "/aprende/cultura-general/cuantos-pisos-tiene-la-torre-de-pemex"
        },
        {
            "id": "0033-ART-APRENDE-TIPS-280524",
            "titulo": "¿Cómo evaluar la calidad de una universidad privada?",
            "fecha": "28 de mayo de 2024",
            "autor": "Iván Solís Rivera",
            "descripcion": "Conoce los puntos clave para elegir la mejor opción educativa y responde a la pregunta: ¿Cómo evaluar la calidad de una universidad privada?",
            "portada": "/images/como-evaluar-calidad-universidad-privada-estudiantes.webp",
            "enlace": "/aprende/tips/puntos-clave-para-evaluar-la-calidad-de-una-universidad-privada"
        },
        {
            "id": "0029-ART-APRENDE-CULTURA-GENERAL-210524",
            "titulo": "Uso responsable de las redes sociales: Guía para navegar de forma segura",
            "fecha": "22 de mayo de 2024",
            "autor": "Iván Solís Rivera",
            "descripcion": "El uso responsable de las redes sociales recae en el nivel de consciencia y educación de los usuarios respecto a los efectos y beneficios de estas plataformas.",
            "portada": "/images/uso-responsable-redes-sociales-beneficios-jovenes.webp",
            "enlace": "/aprende/cultura-general/consejos-sobre-uso-responsable-de-redes-sociales"
        },
        {
            "id": "0028-ART-SER-HUMANITAS-APRENDE-TENDENCIAS-160524",
            "titulo": "Mejores competencias digitales para profesionales",
            "fecha": "17 de mayo de 2024",
            "autor": "Iván Solís Rivera",
            "descripcion": "Conoce cuáles son las competencias digitales para profesionales más relevantes que debes desarrollar para ser un profesional.",
            "portada": "/images/competencias-digitales-profesionales-digitalizacion.webp",
            "enlace": "/aprende/tendencias/competencias-digitales-para-profesionales"
        },
        {
            "id": "25-ART-APRENDE-LICENCIATURA-CULTURA-GRAL-140524",
            "titulo": "Celebrando el Día del Maestro: reconozcamos a nuestros profesores",
            "fecha": "15 de mayo de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "La importancia del Día del Maestro: cómo se celebra en México y la influencia de los profesores en la sociedad. ¡Descubre más!",
            "portada": "/images/dia-del-maestro-mexico-humanitas.webp",
            "enlace": "/aprende/cultura-general/dia-del-maestro-2024"
        },
        {
            "id": "16-ART-APRENDE-TIPS-020424",
            "titulo": "Maximiza tu productividad al estilo de Bill Gates.",
            "fecha": "02 de febrero de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Descubre la técnica de Bill Gates y potencia tu productividad, ¡lee más!",
            "portada": "/images/aumenta-tu-productividad-tecnica-trabajo-profundo-Bill-Gates.jpg",
            "enlace": "/aprende/tips/maximiza-tu-productividad-estilo-bill-gates"
        },
        {
            "id": "15-ART-APRENDE-TENDENCIAS-270324",
            "titulo": "Tendencias en Psicología para 2024: Tecnología en la salud mental",
            "fecha": "28 de marzo de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Conoce las tendencias clave en Psicología en 2024, la salud mental y las aplicaciones digitales. Averigua cómo la tecnología puede ayudar al bienestar.",
            "portada": "/images/tendencias-psicologia-2024-chico-sentado-meditando-frente-laptop.jpg",
            "enlace": "/aprende/tendencias/tendencias-en-psicologia-2024-tecnologia-salud-mental"
        },
        {
            "id": "13-ART-APRENDE-TIPS-150324",
            "titulo": "Estoicismo: cómo puede ayudar a los estudiantes a superar desafíos",
            "fecha": "15 de marzo de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Explora los principios del Estoicismo y cuáles son sus beneficios en tu vida universitaria.",
            "portada": "../images/Que-es-estoicismo-y-como-ayuda-a-universitarios.jpg",
            "enlace": "/aprende/tips/estoicismo-para-estudiantes-universitarios-y-beneficios"
        },
        {
            "id": "11-ART-APRENDE-CULTURA-GENERAL-080324",
            "titulo": "Día de la Mujer 2024: invertir para tener igualdad de género",
            "fecha": "08 de marzo de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Día Internacional de la Mujer: por qué es importante y cómo en 2024, financiar los derechos de las mujeres puede acelerar la igualdad de género.",
            "portada": "../images/Dia-internacional-de-la-mujer-financiar-acelera-igualdad-genero-2024.jpg",
            "enlace": "/aprende/cultura-general/dia-de-la-mujer-financiar-derechos-acelerar-igualdad-de-genero-2024"
        },
        {
            "id": "07-ART-APRENDE-TENDENCIAS-190224",
            "titulo": "Infórmate acerca de las tendencias actuales del Derecho en 2024.",
            "fecha": "19 de febrero de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Entérate cuáles son las principales tendencias actuales del Derecho en 2024. Mantente al día con los cambios e innovaciones que conforman el futuro legal.",
            "portada": "../images/Tendencias-actuales-derecho-2024.jpg",
            "enlace": "/aprende/tendencias/tendencias-actuales-del-derecho-2024"
        },
        {
            "id": "04-ART-APRENDE-TENDENCIAS-310124",
            "titulo": "Estas son las tendencias clave en la administración en 2024, descúbrelas.",
            "fecha": "31 de enero de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Entérate de las tendencias más relevantes que dan forma al futuro de la administración de empresas en 2024.",
            "portada": "../images/Tendencias-administración-empresarial-2024-multinube.jpg",
            "enlace": "/aprende/tendencias/tendencias-administracion-empresas-2024"
        },
        {
            "id": "03-ART-APRENDE-TIPS-240124",
            "titulo": "Domina tus estudios: técnicas y consejos para tu éxito universitario",
            "fecha": "24 de enero de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Descubre las mejores técnicas de estudio y consejos para maximizar tu rendimiento en la universidad. Estudia de manera más inteligente y eficaz.",
            "portada": "../images/Las-mejores-tecnicas-estudio-triunfar-universidad.jpg",
            "enlace": "/aprende/tips/mejores-tecnicas-estudio-para-universidad"
        },
        
    ], // Array de artículos original
    filtros: {
        "0072-ART-APRENDE-TENDENCIAS-130924": {
            Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y teatro"],
            Ciencias_sociales: ["Derecho", "Ciencias Políticas y Administración Pública"],
            Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "0073-ART-APRENDE-TENDENCIAS-130924": {
            Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y teatro"],
            Ciencias_sociales: ["Derecho", "Ciencias Políticas y Administración Pública"],
            Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "0056-ART-APRENDE-CULTURA-GENERAL-070724": {
            Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y teatro"],
            Ciencias_sociales: ["Derecho", "Ciencias Políticas y Administración Pública"],
            Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "0058-ART-APRENDE-TIPS-010824": {
            Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y teatro"],
            Ciencias_sociales: ["Derecho", "Ciencias Políticas y Administración Pública"],
            Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "0057-ART-APRENDE-TIPS-310724": {
            Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y teatro"],
            Ciencias_sociales: ["Derecho", "Ciencias Políticas y Administración Pública"],
            Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "0055-ART-APRENDE-TIPS-050724": {
            Humanidades: ["Psicología"],
            Ciencias_sociales: [],
            Economico_administrativas: []
        },
        "0051-ART-APRENDE-CULTURA-GENERAL-270624": {
            Humanidades: ["Educación", "Psicología"],
            Ciencias_sociales: ["Administración"],
            Economico_administrativas: []
        },
        "0044-ART-APRENDE-CULTURA-GENERAL-130624": {
            Humanidades: ["Arquitectura"],
            Ciencias_sociales: [],
            Economico_administrativas: []
        },
        "0033-ART-APRENDE-TIPS-280524": {
            Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
            Ciencias_sociales: ["Derecho", "Ciencias Políticas y Administración Pública"],
            Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "0029-ART-APRENDE-CULTURA-GENERAL-210524": {
            Humanidades: ["Educación", "Psicología"],
            Ciencias_sociales: [],
            Economico_administrativas: []
        },
        "0028-ART-SER-HUMANITAS-APRENDE-TENDENCIAS-160524": {
            Humanidades: [],
            Ciencias_sociales: [],
            Economico_administrativas: ["Administración"]
        },
        "25-ART-APRENDE-LICENCIATURA-CULTURA-GRAL-140524": {
            Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
            Ciencias_sociales: ["Derecho", "Ciencias Políticas y Administración Pública"],
            Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "16-ART-APRENDE-TIPS-020424": {
            Humanidades: [],
            Ciencias_sociales: [],
            Economico_administrativas: ["Administración"]
        },
        "15-ART-APRENDE-TENDENCIAS-270324": {
            Humanidades: ["Psicología"],
            Ciencias_sociales: [],
            Economico_administrativas: []
        },
        "03-ART-APRENDE-TIPS-240124": {
            Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y teatro"],
            Ciencias_sociales: ["Derecho", "Ciencias Políticas y Administración Pública"],
            Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "04-ART-APRENDE-TENDENCIAS-310124": {
            Humanidades: [],
            Ciencias_sociales: [],
            Economico_administrativas: ["Administración"]
        },
        "07-ART-APRENDE-TENDENCIAS-190224": {
            Humanidades: [],
            Ciencias_sociales: ["Derecho"],
            Economico_administrativas: []
        },
        "11-ART-APRENDE-CULTURA-GENERAL-080324": {
            Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y teatro"],
            Ciencias_sociales: ["Derecho", "Ciencias Políticas y Administración Pública"],
            Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "13-ART-APRENDE-TIPS-150324": {
            Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y teatro"],
            Ciencias_sociales: ["Derecho", "Ciencias Políticas y Administración Pública"],
            Economico_administrativas: []
        },
    },
    filtrosSeleccionados: null, // Lista de filtros seleccionados
    };
},
methods: {
    
}
};
</script>

<style>
/* Estilos CSS si los tienes */
</style>